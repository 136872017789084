import React, { forwardRef, useRef, useState } from "react";
import uuid from "uuid/v4";
import { apiCall } from "../../shared/helpers/networkHelper";
import * as endpoint from "../../shared/utils/endpoints";
import ErrorHandler from "../../shared/classes/ErrorHandler";
import {
    Button,
    Checkbox,
    Col,
    Divider,
    Empty,
    Form,
    Input,
    Modal,
    Radio,
    Row,
    Spin,
    Table
} from "antd";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import SignatureCanvas from "react-signature-canvas";

// TODO: form validation - tanya pak rolando mana yg required mana yg tidak
// TODO: view mode read only
export default function PersetujuanMedis({ datas, getEmrData, userCode }) {
    const initialFormState = {
        alamat: "",
        noRekamMedis: "",
        ttl: "",
        jenisKelamin: "",
        dokterPelaksana: "",
        pemberiInformasi: "",
        penerimaInformasi: "",
        informationItems: [
            { label: "Diagnosis", value: "", checked: false },
            { label: "Dasar Diagnosis", value: "", checked: false },
            { label: "Tindakan Kedokteran", value: "", checked: false },
            { label: "Indikasi Tindakan", value: "", checked: false },
            { label: "Tata Cara", value: "", checked: false },
            { label: "Tujuan", value: "", checked: false },
            { label: "Risiko", value: "", checked: false },
            { label: "Komplikasi", value: "", checked: false },
            { label: "Prognosis", value: "", checked: false },
            { label: "Alternatif dan Risiko", value: "", checked: false }
        ],
        informationConsentItems: [
            { label: "satusehat", checked: false },
            { label: "PMK", checked: false },
            { label: "diskusi", checked: false },
            { label: "paham", checked: false }
        ],
        nama: "",
        ttlPersetujuan: "",
        jenisKelaminPersetujuan: "",
        buktiDiri: "",
        noTelepon: "",
        tindakanMedis: "",
        namaPasien: "",
        noRekam: "",
        ttlPasien: "",
        jenisKelaminPasien: "",
        tempat: "",
        tanggal: "",
        doctorSignature: "",
        patientSignature: ""
    };
    const [formValues, setFormValues] = useState(initialFormState);
    const [isLoading, setIsLoading] = useState(false);
    const [formMode, setFormMode] = useState("add");
    const [showConsentModal, setShowConsentModal] = useState(false);
    const [showDoctorSignaturePad, setShowDoctorSignaturePad] = useState(false);
    const [showPatientSignaturePad, setShowPatientSignaturePad] = useState(
        false
    );
    const doctorSigRef = useRef();
    const patientSigRef = useRef();

    const handleChange = e => {
        const { name, value, type, checked } = e.target;

        setFormValues(prevValues => ({
            ...prevValues,
            [name]: type === "checkbox" ? checked : value
        }));
    };

    const handleNestedChange = (section, index, field, newValue) => {
        setFormValues(prevValues => ({
            ...prevValues,
            [section]: prevValues[section].map((item, i) =>
                i === index ? { ...item, [field]: newValue } : item
            )
        }));
    };

    const handleSaveSignature = type => {
        const signatureDataUrl =
            type === "doctor"
                ? doctorSigRef.current.getTrimmedCanvas().toDataURL()
                : patientSigRef.current.getTrimmedCanvas().toDataURL();
        setFormValues(prevValues => ({
            ...prevValues,
            [type === "doctor"
                ? "doctorSignature"
                : "patientSignature"]: signatureDataUrl
        }));
    };

    const clearSignature = type => {
        if (type === "doctor") {
            doctorSigRef.current.clear();
        } else {
            patientSigRef.current.clear();
        }
    };

    const handleOk = () => {
        setIsLoading(true);

        const currentDatas = Array.isArray(datas) ? datas : [];

        if (formMode === "add") {
            const newEntry = {
                ...formValues,
                uuid: uuid(),
                createdAt: new Date().toISOString()
            };

            const newDatas = [...currentDatas, newEntry];

            apiCall(
                endpoint.GET_CUSTOMER_EMR,
                "put",
                {
                    user_code: userCode,
                    persetujuan_medis: newDatas
                },
                result => {
                    getEmrData(userCode);
                    setShowConsentModal(false);
                    setFormValues(initialFormState);
                    setIsLoading(false);
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                    setIsLoading(false);
                }
            );
        } else {
            const updatedDatas = currentDatas.map(entry =>
                entry.uuid === formValues.uuid
                    ? {
                          ...entry,
                          ...formValues,
                          updatedAt: new Date().toISOString()
                      }
                    : entry
            );

            apiCall(
                endpoint.GET_CUSTOMER_EMR,
                "put",
                {
                    user_code: userCode,
                    persetujuan_medis: updatedDatas
                },
                result => {
                    getEmrData(userCode);
                    setShowConsentModal(false);
                    setFormValues(initialFormState);
                    setIsLoading(false);
                    clearSignature("doctor");
                    clearSignature("patient");
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                    setIsLoading(false);
                }
            );
        }
    };

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 }
        }
    };

    function showDeleteConfirm(record) {
        Modal.confirm({
            title: "Yakin ingin menghapus?",
            content: "Data akan dihapus",
            okText: "Hapus",
            okType: "danger",
            cancelText: "Batal",
            onOk() {
                setIsLoading(true);
                const updatedDatas = datas.filter(
                    entry => entry.uuid !== record.uuid
                );

                apiCall(
                    endpoint.GET_CUSTOMER_EMR,
                    "put",
                    {
                        user_code: userCode,
                        persetujuan_medis:
                            updatedDatas.length > 0 ? updatedDatas : "{}"
                    },
                    result => {
                        getEmrData(userCode);
                        setIsLoading(false);
                    },
                    err => {
                        ErrorHandler.handleGeneralError(err);
                        setIsLoading(false);
                    }
                );
            },
            onCancel() {
                console.log("Cancel");
            }
        });
    }

    return (
        <>
            <div style={{ marginBottom: 30 }}>
                <Button
                    htmlType="button"
                    size="default"
                    type="primary"
                    onClick={() => {
                        setFormMode("add");
                        setShowConsentModal(true);
                    }}
                >
                    Buat Persetujuan Medis Baru
                </Button>
            </div>

            <h4>Riwayat Persetujuan Medis Pelanggan</h4>
            <Spin spinning={isLoading}>
                {Array.isArray(datas) && datas.length > 0 ? (
                    <div style={{ overflowX: "auto" }}>
                        <Table
                            dataSource={datas}
                            rowKey={record => record.uuid}
                            key={datas.length}
                        >
                            <Table.Column
                                title="Tanggal Persetujuan"
                                dataIndex="createdAt"
                                key="createdAt"
                                render={(_, record) =>
                                    moment(record.createdAt).format(
                                        "DD-MM-YYYY"
                                    )
                                }
                            />
                            <Table.Column
                                title="Nomor Rekam Medis"
                                dataIndex="noRekamMedis"
                                key="noRekamMedis"
                            />
                            <Table.Column
                                title="Action"
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                setFormValues(record);
                                                setFormMode("view");
                                                setShowConsentModal(true);
                                            }}
                                            style={{ marginRight: 8 }}
                                        >
                                            Lihat
                                        </Button>
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                setFormValues(record);
                                                setFormMode("edit");
                                                setShowConsentModal(true);
                                            }}
                                            type="primary"
                                            style={{ marginRight: 8 }}
                                        >
                                            Ubah
                                        </Button>
                                        <Button
                                            size="small"
                                            onClick={() =>
                                                showDeleteConfirm(record)
                                            }
                                            type="danger"
                                            style={{ marginRight: 8 }}
                                        >
                                            Hapus
                                        </Button>
                                    </>
                                )}
                            />
                        </Table>
                    </div>
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </Spin>

            <Modal
                title={
                    formMode === "add"
                        ? "Add Persetujuan Medis"
                        : formMode === "edit"
                        ? "Edit Persetujuan Medis"
                        : "View Persetujuan Medis"
                }
                visible={showConsentModal}
                onOk={handleOk}
                onCancel={() => {
                    setShowConsentModal(false);
                    setFormValues(initialFormState);
                    clearSignature("doctor");
                    clearSignature("patient");
                }}
                width="90%"
                okText={
                    formMode === "add"
                        ? "Add Persetujuan Medis"
                        : formMode === "edit"
                        ? "Edit Persetujuan Medis"
                        : "View Persetujuan Medis"
                }
                okButtonProps={{ disabled: formMode === "view" }}
                maskClosable={false}
            >
                <Spin spinning={isLoading}>
                    <Form labelAlign="left" {...formItemLayout}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label="Alamat" htmlFor="alamat">
                                    <Input
                                        name="alamat"
                                        id="alamat"
                                        value={formValues.alamat}
                                        onChange={handleChange}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="No. Rekam Medis"
                                    htmlFor="noRekamMedis"
                                >
                                    <Input
                                        name="noRekamMedis"
                                        id="noRekamMedis"
                                        value={formValues.noRekamMedis}
                                        onChange={handleChange}
                                    />
                                </Form.Item>
                                <Form.Item label="TTL" htmlFor="ttl">
                                    <Input
                                        name="ttl"
                                        id="ttl"
                                        value={formValues.ttl}
                                        onChange={handleChange}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Jenis Kelamin"
                                    htmlFor="jenisKelamin"
                                >
                                    <Radio.Group
                                        name="jenisKelamin"
                                        value={formValues.jenisKelamin}
                                        onChange={handleChange}
                                    >
                                        <Radio value="male">Laki-laki</Radio>
                                        <Radio value="female">Perempuan</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider
                            orientation={
                                window.innerWidth < 500 ? "left" : "center"
                            }
                        >
                            {window.innerWidth < 500 ? (
                                <p>Dokumentasi Pemberian Informasi</p>
                            ) : (
                                <h2>Dokumentasi Pemberian Informasi</h2>
                            )}
                        </Divider>

                        <Form.Item
                            label="Dokter Pelaksana"
                            htmlFor="dokterPelaksana"
                        >
                            <Input
                                name="dokterPelaksana"
                                id="dokterPelaksana"
                                value={formValues.dokterPelaksana}
                                onChange={handleChange}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Pemberi Informasi"
                            htmlFor="pemberiInformasi"
                        >
                            <Input
                                name="pemberiInformasi"
                                id="pemberiInformasi"
                                value={formValues.pemberiInformasi}
                                onChange={handleChange}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Penerima Informasi"
                            htmlFor="penerimaInformasi"
                        >
                            <Input
                                name="penerimaInformasi"
                                id="penerimaInformasi"
                                value={formValues.penerimaInformasi}
                                onChange={handleChange}
                            />
                        </Form.Item>

                        <Row gutter={16}>
                            <Col span={8}>
                                <h3>Jenis Informasi</h3>
                            </Col>
                            <Col span={12}>
                                <h3>Isi Informasi</h3>
                            </Col>
                            <Col span={4}>
                                <h3>Tanda (✓)</h3>
                            </Col>
                        </Row>

                        {/* TODO: change to props (mock actual data) */}
                        {formValues.informationItems.map((item, index) => (
                            <Row key={index} gutter={16}>
                                <Col span={8}>{item.label}</Col>
                                <Col span={12}>
                                    <Form.Item name={`isiInformasi${index}`}>
                                        <Input
                                            value={item.value}
                                            onChange={e =>
                                                handleNestedChange(
                                                    "informationItems",
                                                    index,
                                                    "value",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        name={`tandaInformasi${index}`}
                                        valuepropname="checked"
                                    >
                                        <Checkbox
                                            checked={item.checked}
                                            onChange={e =>
                                                handleNestedChange(
                                                    "informationItems",
                                                    index,
                                                    "checked",
                                                    e.target.checked
                                                )
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ))}

                        {/* TODO: change to props (mock actual data) */}
                        {formValues.informationConsentItems.map(
                            (item, index) => (
                                <Row key={index} gutter={16}>
                                    {item.label === "satusehat" && (
                                        <Col span={20}>
                                            Dengan ini menyetujui untuk menerima
                                            dan membuka data pasien dari
                                            Fasilitas Pelayanan Kesehatan
                                            lainnya melalui SATUSEHAT untuk
                                            kepentingan pelayanan kesehatan
                                            dan/atau rujukan
                                        </Col>
                                    )}
                                    {item.label === "PMK" && (
                                        <Col span={20}>
                                            Dengan ini mengetahui dan menyetujui
                                            bahwa berdasarkan Peraturan Menteri
                                            Kesehatan Nomor 24 tahun 2022
                                            tentang Rekam Medis, fasilitas
                                            pelayanan kesehatan wajib membuka
                                            akses dan mengirim data rekam medis
                                            kepada Kementerian Kesehatan melalui
                                            Platform SATUSEHAT.
                                        </Col>
                                    )}
                                    {item.label === "diskusi" && (
                                        <Col span={20}>
                                            Dengan ini menyatakan bahwa saya
                                            telah menerangkan hal-hal diatas
                                            secara benar dan jujur dan
                                            memberikan kesempatan untuk bertanya
                                            dan/atau berdiskusi.
                                        </Col>
                                    )}
                                    {item.label === "paham" && (
                                        <Col span={20}>
                                            Dengan ini menyatakan bahwa saya
                                            telah menerima informasi sebagaimana
                                            di atas yang saya beri tanda/paraf
                                            dikolom kanannya, dan telah
                                            memahaminya.
                                        </Col>
                                    )}
                                    <Col span={4}>
                                        <Form.Item
                                            name={`tandaConsent${index}`}
                                            valuepropname="checked"
                                        >
                                            <Checkbox
                                                checked={item.checked}
                                                onChange={e =>
                                                    handleNestedChange(
                                                        "informationConsentItems",
                                                        index,
                                                        "checked",
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )
                        )}

                        {/* Dengan ini menyetujui untuk menerima dan membuka data pasien dari Fasilitas Pelayanan Kesehatan lainnya melalui SATUSEHAT untuk kepentingan pelayanan kesehatan dan/atau rujukan */}
                        {/* <Row gutter={16}>
                          <Col span={20}>
                              Dengan ini mengetahui dan menyetujui bahwa berdasarkan Peraturan Menteri Kesehatan Nomor 24 tahun 2022 tentang Rekam Medis, fasilitas pelayanan kesehatan wajib membuka akses dan mengirim data rekam medis kepada Kementerian Kesehatan melalui Platform SATUSEHAT.</Col>
                          <Col span={4}>
                              <Form.Item name="satusehat" valuepropname="checked">
                                  <Checkbox />
                              </Form.Item>
                          </Col>
                      </Row>
                      <Row gutter={16}>
                          <Col span={20}>
                              Dengan ini menyatakan bahwa saya telah menerangkan hal-hal
                              diatas secara benar dan jujur dan memberikan kesempatan
                              untuk bertanya dan/atau berdiskusi.
                          </Col>
                          <Col span={4}>
                              <Form.Item name="satusehat" valuepropname="checked">
                                  <Checkbox />
                              </Form.Item>
                          </Col>
                      </Row>
                      <Row gutter={16}>
                          <Col span={20}>
                              Dengan ini menyatakan bahwa saya telah menerima informasi
                              sebagaimana di atas yang saya beri tanda/paraf dikolom
                              kanannya, dan telah memahaminya.
                          </Col>
                          <Col span={4}>
                              <Form.Item name="satusehat" valuepropname="checked">
                                  <Checkbox />
                              </Form.Item>
                          </Col>
                      </Row> */}

                        <Divider />

                        <Row
                            align="middle"
                            justify="center"
                            type="flex"
                            style={{ padding: "1rem" }}
                        >
                            <Col span={24}>
                                <h2>Persetujuan Tindakan Kedokteran</h2>
                            </Col>
                        </Row>
                        <p>Saya yang bertanda tangan di bawah ini,</p>
                        <Form.Item label="Nama" htmlFor="nama">
                            <Input
                                name="nama"
                                id="nama"
                                value={formValues.nama}
                                onChange={handleChange}
                            />
                        </Form.Item>
                        <Form.Item label="TTL" htmlFor="ttlPersetujuan">
                            <Input
                                name="ttlPersetujuan"
                                id="ttlPersetujuan"
                                value={formValues.ttlPersetujuan}
                                onChange={handleChange}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Jenis Kelamin"
                            name="jenisKelaminPersetujuan"
                            value={formValues.jenisKelamin}
                            onChange={handleChange}
                        >
                            <Radio.Group>
                                <Radio value="male">Laki-laki</Radio>
                                <Radio value="female">Perempuan</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="Bukti Diri KTP" htmlFor="buktiDiri">
                            <Input
                                name="buktiDiri"
                                id="buktiDiri"
                                value={formValues.buktiDiri}
                                onChange={handleChange}
                            />
                        </Form.Item>
                        <Form.Item label="No Telepon" htmlFor="noTelepon">
                            <Input
                                name="noTelepon"
                                id="noTelepon"
                                value={formValues.noTelepon}
                                onChange={handleChange}
                            />
                        </Form.Item>
                        <p>
                            Dengan ini menyatakan dengan sesungguhnya bahwa saya
                            memberikan:
                        </p>
                        <h2 style={{ textAlign: "center" }}>Persetujuan</h2>
                        <p>untuk dilakukan tindakan medis berupa:</p>
                        <Form.Item name="tindakanMedis">
                            <TextArea rows={4} />
                        </Form.Item>
                        <p>
                            Terhadap diri Saya Sendiri/ Istri Saya/ Suami saya/
                            Anak Saya/ Ibu saya, sebagai berikut:
                        </p>
                        <Form.Item label="Nama" htmlFor="namaPasien">
                            <Input
                                name="namaPasien"
                                id="namaPasien"
                                value={formValues.namaPasien}
                                onChange={handleChange}
                            />
                        </Form.Item>
                        <Form.Item label="No Rekam Medis" htmlFor="noRekam">
                            <Input
                                name="noRekam"
                                id="noRekam"
                                value={formValues.noRekam}
                                onChange={handleChange}
                            />
                        </Form.Item>
                        <Form.Item label="TTL" htmlFor="ttlPasien">
                            <Input
                                name="ttlPasien"
                                id="ttlPasien"
                                value={formValues.ttlPasien}
                                onChange={handleChange}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Jenis Kelamin"
                            name="jenisKelaminPasien"
                            value={formValues.jenisKelamin}
                            onChange={handleChange}
                        >
                            <Radio.Group>
                                <Radio value="male">Laki-laki</Radio>
                                <Radio value="female">Perempuan</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <p>
                            Saya memahami perlunya dan manfaat tindakan tersebut
                            sebagaimana telah dijelaskan seperti di atas kepada
                            saya, termasuk risiko dan komplikasi yang mungkin
                            timbul. Saya juga menyadari bahwa oleh karena ilmu
                            kedokteran bukanlah ilmu pasti, maka keberhasilan
                            tindakan kedokteran bukanlah keniscayaan, melainkan
                            sangat bergantung kepada izin Tuhan Yang Maha Esa.
                        </p>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Tempat" htmlFor="tempat">
                                    <Input
                                        name="tempat"
                                        id="tempat"
                                        value={formValues.tempat}
                                        onChange={handleChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Tanggal" htmlFor="tanggal">
                                    <Input
                                        name="tanggal"
                                        id="tanggal"
                                        value={formValues.tanggal}
                                        onChange={handleChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8}>
                                <p>Saksi-Saksi</p>
                            </Col>
                            <Col xs={24} sm={8}>
                                <p>Dokter</p>
                                {formValues.doctorSignature !== "" && (
                                    <img
                                        src={formValues.doctorSignature}
                                        alt={"patient sign"}
                                        style={{
                                            width: "100%",
                                            height: "100px",
                                            marginBottom: "10px",
                                            objectFit: "contain"
                                        }}
                                    />
                                )}
                                <Button
                                    onClick={() =>
                                        setShowDoctorSignaturePad(true)
                                    }
                                    disabled={formMode === "view"}
                                >
                                    Digital Signature
                                </Button>
                            </Col>
                            <Col xs={24} sm={8}>
                                <p>Yang Memberikan Persetujuan</p>
                                {formValues.patientSignature !== "" && (
                                    <img
                                        src={formValues.patientSignature}
                                        alt={"patient sign"}
                                        style={{
                                            width: "100%",
                                            height: "100px",
                                            marginBottom: "10px",
                                            objectFit: "contain"
                                        }}
                                    />
                                )}
                                <Button
                                    onClick={() =>
                                        setShowPatientSignaturePad(true)
                                    }
                                    disabled={formMode === "view"}
                                >
                                    Digital Signature
                                </Button>
                            </Col>
                            <SignaturePadModal
                                ref={doctorSigRef}
                                visible={showDoctorSignaturePad}
                                onClose={() => {
                                    setShowDoctorSignaturePad(false);
                                    clearSignature("doctor");
                                }}
                                onSave={() => {
                                    handleSaveSignature("doctor");
                                    setShowDoctorSignaturePad(false);
                                }}
                                onClear={() => clearSignature("doctor")}
                            />

                            <SignaturePadModal
                                ref={patientSigRef}
                                visible={showPatientSignaturePad}
                                onClose={() => {
                                    setShowPatientSignaturePad(false);
                                    clearSignature("patient");
                                }}
                                onSave={() => {
                                    handleSaveSignature("patient");
                                    setShowPatientSignaturePad(false);
                                }}
                                onClear={() => clearSignature("patient")}
                            />
                        </Row>
                        <p style={{ marginTop: "2rem" }}>
                            Dengan ini saya menyatakan bahwa saya telah menerima
                            dan memahami hak dan kewajiban saya sebagai pasien dan
                            petugas telah menjelaskan terkait hak dan kewajiban pasien.
                        </p>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
}

export const SignaturePadModal = forwardRef(
    ({ visible, onClose, onSave, onClear }, ref) => {
        return (
            <Modal
                title="Signature Pad"
                visible={visible}
                onOk={onSave}
                onCancel={onClose}
                okText="Save Signature"
            >
                <SignatureCanvas
                    ref={ref}
                    penColor="black"
                    canvasProps={{
                        width: 500,
                        height: 200,
                        className: "sigCanvas"
                    }}
                />
                <Button onClick={onClear} style={{ marginTop: "10px" }}>
                    Clear Signature
                </Button>
            </Modal>
        );
    }
);
