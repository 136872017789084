import React, { useState } from "react";
import uuid from "uuid/v4";
import { apiCall } from "../../shared/helpers/networkHelper";
import * as endpoint from "../../shared/utils/endpoints";
import ErrorHandler from "../../shared/classes/ErrorHandler";
import {
    Button,
    Col,
    Descriptions,
    Empty,
    Input,
    Modal,
    Radio,
    Row,
    Spin,
    Table
} from "antd";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";

// TODO: form validation - tanya pak rolando mana yg required mana yg tidak
export default function ScreeningRawatJalan({ datas, getEmrData, userCode }) {
    const initalFormState = {
        beratBadan: 0,
        tinggiBadan: 0,
        asalRujukan: "",
        alamat: "",
        pernafasan: "",
        getUpAndGoTest: {
            caraBerjalan: "",
            alatBantu: "",
            menopangDiri: ""
        },
        resikoJatuh: "",
        nyeriDada: "",
        skalaNyeri: "",
        batuk: "",
        keputusan: ""
    };
    const [formMode, setFormMode] = useState("add");
    const [showScreeningModal, setShowScreeningModal] = useState(false);
    const [formData, setFormData] = useState(initalFormState);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState("");

    const handleInputChange = key => e => {
        const value = e.target ? e.target.value : e; // Handle Radio and Select values
        setFormData(prevData => ({
            ...prevData,
            [key]: value
        }));
    };

    const handleGetUpAndGoChange = subKey => e => {
        const value = e.target ? e.target.value : e; // Handle Radio values
        setFormData(prevData => ({
            ...prevData,
            getUpAndGoTest: {
                ...prevData.getUpAndGoTest,
                [subKey]: value
            }
        }));
    };

    const handleOk = () => {
        setIsLoading(true);

        const currentDatas = Array.isArray(datas) ? datas : [];

        if (formMode === "add") {
            const newEntry = {
                ...formData,
                uuid: uuid(),
                createdAt: new Date().toISOString()
            };

            const newDatas = [...currentDatas, newEntry];

            apiCall(
                endpoint.GET_CUSTOMER_EMR,
                "put",
                {
                    user_code: userCode,
                    screening_rawat_jalan: newDatas
                },
                result => {
                    getEmrData(userCode);
                    setShowScreeningModal(false);
                    setFormData(initalFormState);
                    setIsLoading(false);
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                    setIsLoading(false);
                }
            );
        } else {
            const updatedDatas = currentDatas.map(entry =>
                entry.uuid === formData.uuid
                    ? {
                          ...entry,
                          ...formData,
                          updatedAt: new Date().toISOString()
                      }
                    : entry
            );

            apiCall(
                endpoint.GET_CUSTOMER_EMR,
                "put",
                {
                    user_code: userCode,
                    screening_rawat_jalan: updatedDatas
                },
                result => {
                    getEmrData(userCode);
                    setShowScreeningModal(false);
                    setFormData(initalFormState);
                    setIsLoading(false);
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                    setIsLoading(false);
                }
            );
        }
    };

    function showDeleteConfirm(record) {
        Modal.confirm({
            title: "Yakin ingin menghapus?",
            content: "Data akan dihapus",
            okText: "Hapus",
            okType: "danger",
            cancelText: "Batal",
            onOk() {
                setIsLoading(true);
                const updatedDatas = datas.filter(
                    entry => entry.uuid !== record.uuid
                );

                apiCall(
                    endpoint.GET_CUSTOMER_EMR,
                    "put",
                    {
                        user_code: userCode,
                        screening_rawat_jalan:
                            updatedDatas.length > 0 ? updatedDatas : "{}"
                    },
                    result => {
                        getEmrData(userCode);
                        setIsLoading(false);
                    },
                    err => {
                        ErrorHandler.handleGeneralError(err);
                        setIsLoading(false);
                    }
                );
            },
            onCancel() {
                console.log("Cancel");
            }
        });
    }

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    return (
        <>
            <div style={{ marginBottom: "2rem" }}>
                <Button
                    htmlType="button"
                    size="default"
                    type="primary"
                    onClick={() => {
                        setFormMode("add");
                        setShowScreeningModal(true);
                    }}
                >
                    Buat Screening Rawat Jalan Baru
                </Button>
            </div>

            <h4>Daftar Screening Rawat Jalan Pelanggan</h4>
            <Spin spinning={isLoading}>
                {Array.isArray(datas) && datas.length > 0 ? (
                    <div style={{ overflowX: "auto" }}>
                        <Table
                            dataSource={datas}
                            rowKey={record => record.uuid}
                            key={datas.length}
                        >
                            <Table.Column
                                title="Tanggal Datang"
                                dataIndex="tanggalDatang"
                                key="tanggalDatang"
                                render={(_, record) =>
                                    moment(record.createdAt).format(
                                        "DD-MM-YYYY"
                                    )
                                }
                            />
                            <Table.Column
                                title="Jam Datang"
                                dataIndex="jamDatang"
                                key="jamDatang"
                                render={(_, record) =>
                                    moment(record.createdAt).format("HH:mm:ss")
                                }
                            />
                            <Table.Column
                                title="Asal Rujukan"
                                dataIndex="asalRujukan"
                                key="asalRujukan"
                            />
                            <Table.Column title="Alamat" dataIndex="alamat" />
                            <Table.Column
                                title="Action"
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                setFormData(record);
                                                setFormMode("view");
                                                setShowScreeningModal(true);
                                            }}
                                            style={{ marginRight: 8 }}
                                        >
                                            Lihat
                                        </Button>
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                setFormData(record);
                                                setFormMode("edit");
                                                setShowScreeningModal(true);
                                            }}
                                            type="primary"
                                            style={{ marginRight: 8 }}
                                        >
                                            Ubah
                                        </Button>
                                        <Button
                                            size="small"
                                            onClick={() =>
                                                showDeleteConfirm(record)
                                            }
                                            type="danger"
                                            style={{ marginRight: 8 }}
                                        >
                                            Hapus
                                        </Button>
                                    </>
                                )}
                            />
                        </Table>
                    </div>
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </Spin>

            <Modal
                title={
                    formMode === "add"
                        ? "Add Screening Rawat Jalan"
                        : formMode === "edit"
                        ? "Edit Screening Rawat Jalan"
                        : "View Screening Rawat Jalan"
                }
                visible={showScreeningModal}
                onOk={handleOk}
                onCancel={() => {
                    setShowScreeningModal(false);
                    setFormData(initalFormState);
                }}
                width="75%"
                okText={
                    formMode === "add"
                        ? "Add Screening Rawat Jalan"
                        : formMode === "edit"
                        ? "Edit Screening Rawat Jalan"
                        : "View Screening Rawat Jalan"
                }
                okButtonProps={{ disabled: formMode === "view" }}
                maskClosable={false}
                style={{ overflowX: "auto" }}
            >
                <Spin spinning={isLoading}>
                    <Descriptions
                        column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}
                    >
                        <Descriptions.Item label="Tanggal Datang">
                            {moment().format("DD MMMM YYYY")}
                        </Descriptions.Item>
                        <Descriptions.Item label="Jam Datang">
                            {moment().format("HH:mm")}
                        </Descriptions.Item>
                    </Descriptions>

                    <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
                        <Col xs={24} sm={16} style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                            <Row type="flex" gutter="10px">
                                <div>
                                    {formMode === "view" ? (
                                        <span style={{ marginRight: "2rem" }}>
                                            Berat Badan (Kg): {formData.beratBadan === 0
                                            ? "-" : formData.beratBadan === undefined
                                            ? "-" : formData.beratBadan}
                                        </span>
                                    ) : (
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <label style={{width: "40%"}}>Berat Badan</label>
                                            <Input
                                                addonAfter="Kg"
                                                type="number"
                                                id="beratBadan"
                                                value={formData.beratBadan}
                                                onChange={handleInputChange(
                                                    "beratBadan"
                                                )}
                                                style={{ width: "200px", marginRight: "1rem" }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div>
                                    {formMode === "view" ? (
                                        <span>
                                            Tinggi Badan (Cm): {formData.tinggiBadan === 0
                                            ? "-" : formData.tinggiBadan === undefined
                                            ? "-" : formData.tinggiBadan}
                                        </span>
                                    ) : (
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <label style={{width: "40%"}}>Tinggi Badan</label>
                                            <Input
                                                addonAfter="Cm"
                                                type="number"
                                                id="tinggiBadan"
                                                value={formData.tinggiBadan}
                                                onChange={handleInputChange(
                                                    "tinggiBadan"
                                                )}
                                                style={{ width: "200px" }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Row>
                            <div>
                                {formMode === "view" ? (
                                    <span>
                                        Asal Rujukan: {formData.asalRujukan}
                                    </span>
                                ) : (
                                    <div style={{ display: "flex", alignItems: "center", gap: "2rem"}}>
                                        <label style={{width: "20%"}}>Asal Rujukan</label>
                                        <Input
                                            id="asalRujukan"
                                            value={formData.asalRujukan}
                                            onChange={handleInputChange(
                                                "asalRujukan"
                                            )}
                                        />
                                    </div>
                                )}
                            </div>
                            <div>
                                {formMode === "view" ? (
                                    <span>Alamat: {formData.alamat}</span>
                                ) : (
                                    <div style={{ display: "flex", alignItems: "center", gap: "2rem"}}>
                                        <label style={{width: "20%"}}>Alamat</label>
                                        <TextArea
                                            rows={5}
                                            id="alamat"
                                            value={formData.alamat}
                                            onChange={handleInputChange("alamat")}
                                            style={{ width: "100%" }}
                                        />
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>

                    <Descriptions
                        title="PEMERIKSAAN"
                        bordered
                        layout={
                            window.innerWidth <= 576 ? "vertical" : "horizontal"
                        }
                        column={1}
                    >
                        <Descriptions.Item label="Pernafasan">
                            {formMode === "view" ? (
                                <span>
                                    {formData.pernafasan === "sesak"
                                        ? "Tampak Sesak"
                                        : formData.pernafasan === "normal"
                                        ? "Nafas Normal"
                                        : ""}
                                </span>
                            ) : (
                                <Radio.Group
                                    value={formData.pernafasan}
                                    onChange={handleInputChange("pernafasan")}
                                >
                                    <Radio value="normal">Nafas Normal</Radio>
                                    <Radio value="sesak">Tampak Sesak</Radio>
                                </Radio.Group>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Get Up & Go Test">
                            <p style={{ marginBottom: "-1px" }}>
                                a. Cara berjalan pasien:
                            </p>
                            <ul>
                                <li>
                                    Tidak seimbang / limbung / sempoyongan?
                                    {formMode === "view" ? (
                                        <span>
                                            {formData.getUpAndGoTest
                                                .caraBerjalan === "ya"
                                                ? " Ya"
                                                : formData.getUpAndGoTest
                                                      .caraBerjalan === "tidak"
                                                ? " Tidak"
                                                : ""}
                                        </span>
                                    ) : (
                                        <Radio.Group
                                            value={
                                                formData.getUpAndGoTest
                                                    .caraBerjalan
                                            }
                                            style={{ marginLeft: "1em" }}
                                            onChange={handleGetUpAndGoChange(
                                                "caraBerjalan"
                                            )}
                                        >
                                            <Radio value="ya">Ya</Radio>
                                            <Radio value="tidak">Tidak</Radio>
                                        </Radio.Group>
                                    )}
                                </li>
                                <li>
                                    Jalan Menggunakan Alat Bantu?
                                    {formMode === "view" ? (
                                        <span>
                                            {formData.getUpAndGoTest
                                                .alatBantu === "ya"
                                                ? " Ya"
                                                : formData.getUpAndGoTest
                                                      .alatBantu === "tidak"
                                                ? " Tidak"
                                                : ""}
                                        </span>
                                    ) : (
                                        <Radio.Group
                                            value={
                                                formData.getUpAndGoTest
                                                    .alatBantu
                                            }
                                            style={{ marginLeft: "1em" }}
                                            onChange={handleGetUpAndGoChange(
                                                "alatBantu"
                                            )}
                                        >
                                            <Radio value="ya">Ya</Radio>
                                            <Radio value="tidak">Tidak</Radio>
                                        </Radio.Group>
                                    )}
                                </li>
                            </ul>
                            <p
                                style={{
                                    marginTop: "4px",
                                    marginBottom: "-1px"
                                }}
                            >
                                b. Menopang saat akan duduk:
                            </p>
                            <ul>
                                <li>
                                    Tampak memegang pinggiran kursi atau meja
                                    atau beda lain sebagai penompang?
                                    {formMode === "view" ? (
                                        <span>
                                            {formData.getUpAndGoTest
                                                .menopangDiri === "ya"
                                                ? " Ya"
                                                : formData.getUpAndGoTest
                                                      .menopangDiri === "tidak"
                                                ? " Tidak"
                                                : ""}
                                        </span>
                                    ) : (
                                        <Radio.Group
                                            value={
                                                formData.getUpAndGoTest
                                                    .menopangDiri
                                            }
                                            style={{ marginLeft: "1em" }}
                                            onChange={handleGetUpAndGoChange(
                                                "menopangDiri"
                                            )}
                                        >
                                            <Radio value="ya">Ya</Radio>
                                            <Radio value="tidak">Tidak</Radio>
                                        </Radio.Group>
                                    )}
                                </li>
                            </ul>
                        </Descriptions.Item>
                        <Descriptions.Item label="Resiko Jatuh">
                            {formMode === "view" ? (
                                <span>
                                    {formData.resikoJatuh === "tidak"
                                        ? "Tidak beresiko"
                                        : formData.resikoJatuh === "rendah"
                                        ? "Risiko rendah"
                                        : formData.resikoJatuh === "tinggi"
                                        ? "Risiko tinggi"
                                        : ""}
                                </span>
                            ) : (
                                <Radio.Group
                                    value={formData.resikoJatuh}
                                    onChange={handleInputChange("resikoJatuh")}
                                >
                                    <Radio value="tidak">Tidak beresiko</Radio>
                                    <Radio value="rendah">Risiko rendah</Radio>
                                    <Radio value="tinggi">Risiko tinggi</Radio>
                                </Radio.Group>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Nyeri Dada">
                            {formMode === "view" ? (
                                <span>
                                    {formData.nyeriDada === "tidak"
                                        ? "Tidak ada"
                                        : formData.nyeriDada === "ada"
                                        ? "Ada (skala sedang)"
                                        : ""}
                                </span>
                            ) : (
                                <Radio.Group
                                    value={formData.nyeriDada}
                                    onChange={handleInputChange("nyeriDada")}
                                >
                                    <Radio value="tidak">Tidak ada</Radio>
                                    <Radio value="ada">
                                        Ada (skala sedang)
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Skala Nyeri">
                            {formMode === "view" ? (
                                <span>{formData.skalaNyeri}</span>
                            ) : (
                                <Radio.Group
                                    value={formData.skalaNyeri}
                                    onChange={handleInputChange("skalaNyeri")}
                                >
                                    <Radio style={radioStyle} value="1-3">1 - 3</Radio>
                                    <Radio style={radioStyle} value="3-5">3 - 5</Radio>
                                    <Radio style={radioStyle} value="5-8">5 - 8</Radio>
                                    <Radio style={radioStyle} value="8-10">8 - 10</Radio>
                                </Radio.Group>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Batuk">
                            {formMode === "view" ? (
                                <span>
                                    {formData.batuk === "tidak"
                                        ? "Tidak ada"
                                        : formData.batuk === "<2minggu"
                                        ? "Batuk < 2 Minggu"
                                        : formData.batuk === ">2minggu"
                                        ? "Batuk > 2 Minggu"
                                        : ""}
                                </span>
                            ) : (
                                <Radio.Group
                                    value={formData.batuk}
                                    onChange={handleInputChange("batuk")}
                                >
                                    <Radio value="tidak">Tidak ada</Radio>
                                    <Radio value="<2minggu">
                                        Batuk {"<"} 2 Minggu
                                    </Radio>
                                    <Radio value=">2minggu">
                                        Batuk {">"} 2 Minggu
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Glassgow Coma Scale (GCS)">
                            {formMode === "view" ? (
                                <span>
                                    {formData.gcs === "14-15"
                                        ? "Skor 14-15 (Compos Mentis)"
                                        : formData.gcs === "12-13"
                                        ? "Skor 12-13 (Apatis)"
                                        : formData.gcs === "11-12"
                                        ? "Skor 11-12 (Somnolent)"
                                        : formData.gcs === "8-10"
                                        ? "Skor 8-10 (Stupor)"
                                        : formData.gcs === "<5"
                                        ? "Skor <5 (Koma)"
                                        : ""}
                                </span>
                            ) : (
                                <Radio.Group
                                    value={formData.gcs}
                                    onChange={handleInputChange("gcs")}
                                >
                                    <Radio style={radioStyle} value="14-15">Skor 14-15 (Compos Mentis)</Radio>
                                    <Radio style={radioStyle} value="12-13">Skor 12-13 (Apatis)</Radio>
                                    <Radio style={radioStyle} value="11-12">Skor 11-12(Somnolent)</Radio>
                                    <Radio style={radioStyle} value="8-10">Skor 8-10(Stupor)</Radio>
                                    <Radio style={radioStyle} value="<5">Skor {"<"}5 (Koma)</Radio>
                                </Radio.Group>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Hubungan pasien dengan anggota keluarga">
                            {formMode === "view" ? (
                                <span>
                                    {formData.hubungan === "baik"
                                        ? "Baik"
                                        : formData.hubungan === "tidakBaik"
                                        ? "Tidak Baik"
                                        : ""}
                                </span>
                            ) : (
                                <Radio.Group
                                    value={formData.hubungan}
                                    onChange={handleInputChange("hubungan")}
                                >
                                    <Radio value="baik">Baik</Radio>
                                    <Radio value="tidakBaik">Tidak Baik</Radio>
                                </Radio.Group>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Status Psikologis">
                            {formMode === "view" ? (
                                <div>
                                    <span>
                                        {formData.status === "tenang"
                                            ? "Tenang"
                                            : formData.status === "cemas"
                                            ? "Cemas"
                                            : formData.status === "takut"
                                            ? "Takut"
                                            : formData.status === "marah"
                                            ? "Marah"
                                            : formData.status === "sedih"
                                            ? "Sedih"
                                            : formData.status === "kecenderunganBD"
                                            ? "Kecenderungan Bunuh Diri"
                                            : formData.status === "lain"
                                            ? "Lain-Lain"
                                            : ""}
                                    </span>
                                    {(formData.status === "lain" || formData.status === "kecenderunganBD") && (
                                        <span>
                                            {": "}{formData.statusText}
                                        </span>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <Radio.Group
                                        value={formData.status}
                                        onChange={handleInputChange("status")}
                                    >
                                        <Radio style={radioStyle} value="tenang">Tenang</Radio>
                                        <Radio style={radioStyle} value="cemas">Cemas</Radio>
                                        <Radio style={radioStyle} value="takut">Takut</Radio>
                                        <Radio style={radioStyle} value="marah">Marah</Radio>
                                        <Radio style={radioStyle} value="sedih">Sedih</Radio>
                                        <Radio style={radioStyle} value="kecenderunganBD">Kecenderungan Bunuh Diri</Radio>
                                        <Radio style={radioStyle} value="lain">Lain-Lain</Radio>
                                    </Radio.Group>
                                    {
                                        (formData.status === "lain" || formData.status === "kecenderunganBD") && 
                                            <Input
                                                addonBefore={formData.status === "lain" ? "Lain-Lain" : "Dilaporkan ke"}
                                                id="statusText"
                                                value={formData.statusText}
                                                onChange={handleInputChange("statusText")}
                                                style={{ width: "100%", marginTop: "1rem" }}
                                            />
                                    }
                                </>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Status Gizi">
                            {formMode === "view" ? (
                                <span>{formData.gizi}</span>
                            ) : (
                                <TextArea
                                    rows={5}
                                    id="gizi"
                                    value={formData.gizi}
                                    onChange={handleInputChange("gizi")}
                                    style={{ width: "100%" }}
                                />
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Tanda-Tanda Vital (Tensi, Nadi, Suhu)">
                            {formMode === "view" ? (
                                <div>
                                    <div>TD: {formData.td}</div>
                                    <div>Suhu: {formData.suhu}</div>
                                    <div>Nadi: {formData.nadi}</div>
                                </div>
                            ) : (
                                <>
                                    <Input
                                        addonBefore="TD"
                                        id="td"
                                        value={formData.td}
                                        onChange={handleInputChange("td")}
                                        style={{ width: "100%", marginBottom: "1rem" }}
                                    />
                                    <Input
                                        addonBefore="Suhu"
                                        id="suhu"
                                        value={formData.suhu}
                                        onChange={handleInputChange("suhu")}
                                        style={{ width: "100%", marginBottom: "1rem" }}
                                    />
                                    <Input
                                        addonBefore="Nadi"
                                        id="nadi"
                                        value={formData.nadi}
                                        onChange={handleInputChange("nadi")}
                                        style={{ width: "100%" }}
                                    />
                                </>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Riwayat Kesehatan Dahulu">
                            {formMode === "view" ? (
                                <span>{formData.riwayat}</span>
                            ) : (
                                <TextArea
                                    rows={5}
                                    id="riwayat"
                                    value={formData.riwayat}
                                    onChange={handleInputChange("riwayat")}
                                    style={{ width: "100%" }}
                                />
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Keputusan">
                            {formMode === "view" ? (
                                <span>
                                    {formData.keputusan === "sesuai"
                                        ? "Sesuai antrian"
                                        : formData.keputusan === "poli"
                                        ? "Poliklinik disegerakan"
                                        : formData.keputusan === "tindakan"
                                        ? "Ruang tindakan"
                                        : ""}
                                </span>
                            ) : (
                                <Radio.Group
                                    value={formData.keputusan}
                                    onChange={handleInputChange("keputusan")}
                                >
                                    <Radio value="sesuai">Sesuai antrian</Radio>
                                    <Radio value="poli">
                                        Poliklinik disegerakan
                                    </Radio>
                                    <Radio value="tindakan">
                                        Ruang tindakan
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
            </Modal>
        </>
    );
}

